import { MRT_RowData } from 'material-react-table';

export type SimpleObject = { [key: string]: any };
export type SimpleStringObject = { [key: string]: string };
export type CustomObject<T> = { [key: string]: T };

export interface PostgresInterval {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
  microseconds?: number;
}

export type ColumnComponent<T> = {
  begin: boolean;
  column: Omit<MRT_RowData, 'name' | 'selector'> & {
    cell: (row: T) => JSX.Element;
    name?: string;
    selector?: string;
  };
};

export enum ImageFormats {
  Png = 'png',
  Jpg = 'jpeg'
}

export enum GlobalZoneValues {
  all = 'Todos',
  NorteGrande = 'Norte Grande',
  NorteChico = 'Norte Chico',
  Centro = 'Centro',
  CentroSur = 'Centro Sur',
  Sur = 'Sur'
}

export enum UserTypeValues {
  admin = 'ADMIN',
  operador = 'OPERADOR',
  boss = 'JEFE',
  supervisor = 'SUPERVISOR',
  management = 'GERENCIA'
}

export enum UserLevels {
  levelOne = 'Nivel 1',
  levelTwo = 'Nivel 2',
  levelThree = 'Nivel 3'
}

export enum StatusBudgetNames {
  pending = 'PENDIENTE',
  rejected = 'RECHAZADO',
  approved = 'APROBADO',
  pendingResolution = 'PENDIENTE POR RESOLUCION',
  editingStage = 'FASE DE EDICION'
}

export enum ManagementTypeNames {
  porteo = 'Porteo',
  acarreo = 'Acarreo',
  ambos = 'Ambos'
}

export const StatusBudgetNamesArray: string[] = Object.values(StatusBudgetNames);

export const modules = {
  SD: {
    name: 'Servicio de Distribución',
    abbreviation: 'SD'
  },
  PORTEO: {
    name: 'Planificación Porteo',
    abbreviation: 'PORTEO'
  },
  ACARREO: {
    name: 'Acarreo',
    abbreviation: 'ACARREO'
  }
};

export type ModuleOption = keyof typeof modules;

export enum IfacType {
  netValue = 'netValue',
  palletQuantity = 'palletQuantity'
}

export enum MajorPortingType {
  contingencyWithholding = 'contingencyWithholding',
  severancePay = 'severancePay'
}

export enum StateValues {
  Activo = 'Activo'
}

export enum FortnightNumber {
  FirstFortnight = 'firstFortnight',
  SecondFortnight = 'secondFortnight',
  FullMonth = 'fullMonth'
}

export const FortnightNumberValues = Object.values(FortnightNumber);

export enum FortnightNames {
  firstFortnight = 'Primera Quincena',
  secondFortnight = 'Segunda Quincena',
  fullMonth = 'Mes Completo'
}

export enum BudgetTypeCarriageNames {
  pptoIngresoAcarreo = 'Ppto Ingreso Acarreo',
  pptoGastoRepro = 'Ppto Gasto Repro',
  pptoGastoAcarreo = 'Ppto Gasto Acarreo',
  volumenRepro = 'Volumen Repro'
}

export interface UserType extends BasicEntity {
  userTypeId: string;
  userTypeName: string;
}

export interface CostCenter extends BasicEntity {
  costCenterId: string;
  costCenterSap: string;
  codeCostCenter: string;
  costCenterName: string;
  description: string;
  zoneDescrp: string;
  userCreaterd: string;
  userUpdated: string;
  regionId: string;
  userId: string;
}

export interface Zone extends BasicEntity {
  zoneId: string;
  zoneDescrp: string;
  zoneGlobalDescrp: string;
  Zone: string;
  regionId: string;
  userCreaterd: string;
  userUpdated: string;
}

export enum ZoneGlobalValues {
  NorteGrande = 'Norte Grande',
  NorteChico = 'Norte Chico',
  CentroSur = 'Centro Sur',
  Sur = 'Sur',
  Centro = 'Centro',
  ADMIN = 'ADMIN',
  VRegion = 'V Region',
  Generico = 'Generico',
  Administracion = 'Administración',
  CobrosUEN = 'Cobros UEN',
  Reponedores = 'Reponedores',
  RRHH = 'RRHH',
  Activos = 'Activos'
}

export const globalZones = Object.values(ZoneGlobalValues);

export interface ZoneGlobal extends BasicEntity {
  zoneGlobalId: string;
  zoneGlobalDescrp: string;
  zoneId: string;
  zoneCostCenterId: string;
  userCreaterd: string;
  userUpdated: string;
}

/* PRESUPUESTO */
export interface Budget extends BasicEntity {
  totalGeneral: string;
  categoriesName: string;
  accountSap: string;
  accountDescription: string;
  costCenterSap: string;
  costCenterName: string;
  zoneGlobalDescrp: string;
  zoneDescrp: string;
  subCategoriesDescription: string;
  budgetTypeName: string;
  enero: string;
  febrero: string;
  marzo: string;
  abril: string;
  mayo: string;
  junio: string;
  julio: string;
  agosto: string;
  septiembre: string;
  octubre: string;
  noviembre: string;
  diciembre: string;
}

export interface Expenses extends BasicEntity {
  accountSap: string;
  costCenterName: string;
  accountDescription: string;
  categoriesName: string;
  userUsername: string;
  total: string;
}
export interface Provision extends BasicEntity {
  correlative: string;
  ledgerGroup: string;
  society: string;
  documentType: string;
  reference: string;
  documentDate: string;
  accountingDate: string;
  coinDescription: string;
  dateConversion: string;
  changeType: string;
  textDocument: string;
  referenceDocument: string;
  typeAccount: string;
  accountSap: string;
  amountMd: string;
  assignment: string;
  taxAmount: string;
  taxindicator: string;
  text: string;
  paymentCondition: string;
  paymentMethod: string;
  factureReference: string;
  invoiceExercise: string;
  positionRefFacture: string;
  centroBeneficio: string;
  referenceOne: string;
  referencetwo: string;
  referencethree: string;
  accountClass: string;
  treasuryDate: string;
  centroCoste: string;
  elementPep: string;
  orderNumber: string;
  societyGl: string;
  mybank: string;
  accountBank: string;
  distributionChanel: string;
  sectoring: string;
  sellOrganization: string;
  groupSeller: string;
  salesOffice: string;
  materiales: string;
  cmeIndicator: string;
}

export interface RefusedProvision extends BasicEntity {
  comment: string;
  correlative: string;
  ledgerGroup: string;
  society: string;
  documentType: string;
  documentDate: string;
  accountingDate: string;
  coinDescription: string;
  textDocument: string;
  typeAccount: string;
  accountSap: string;
  accountDescription: string;
  amountMd: string;
  assignment: string;
  text: string;
  centroBeneficio: string;
  costCenterName: string;
  centroCoste: string;
}

export interface Reclassification extends BasicEntity {
  correlative: string;
  ledgerGroup: string;
  society: string;
  documentType: string;
  reference: string;
  documentDate: string;
  accountingDate: string;
  coinDescription: string;
  dateConversion: string;
  changeType: string;
  textDocument: string;
  referenceDocument: string;
  typeAccount: string;
  accountSap: string;
  amountMd: string;
  assignment: string;
  taxAmount: string;
  taxindicator: string;
  text: string;
  paymentCondition: string;
  paymentMethod: string;
  factureReference: string;
  invoiceExercise: string;
  positionRefFacture: string;
  centroBeneficio: string;
  referenceOne: string;
  referencetwo: string;
  referencethree: string;
  accountClass: string;
  treasuryDate: string;
  centroCoste: string;
  elementPep: string;
  orderNumber: string;
  societyGl: string;
  mybank: string;
  accountBank: string;
  distributionChanel: string;
  sectoring: string;
  sellOrganization: string;
  groupSeller: string;
  salesOffice: string;
  materiales: string;
  cmeIndicator: string;
}

export interface RefusedReclassification extends BasicEntity {
  comment: string;
  correlative: string;
  ledgerGroup: string;
  society: string;
  documentType: string;
  documentDate: string;
  accountingDate: string;
  coinDescription: string;
  textDocument: string;
  typeAccount: string;
  accountSap: string;
  accountDescription: string;
  amountMd: string;
  assignment: string;
  text: string;
  centroBeneficio: string;
  costCenterName: string;
  centroCoste: string;
}

export interface BudgetType extends BasicEntity {
  budgetTypeId: string;
  budgetTypeName: string;
  budgetTypeIndicator: string;
  currentYear: string;
}
export interface EstimatedClosing extends BasicEntity {
  zoneGlobalDescrp: string;
  zoneDescrp: string;
  description: string;
  categoriesName: string;
  accountSap: string;
  ctaPs: string;
  accountDescription: string;
  presupuestoAnualTotal: string;
  estimatedTotal: string;
  diferencia: string;
  porcentajeDiferencia: number;
}

export interface EstimatedClosedPending {
  estimatedDescription: string;
  file: string;
  type: string;
  fileName: string;
}

export interface PendingEstimatedClose extends BasicEntity {
  estimatedDescription: string;
  imageUrl: string;
  zoneGlobalDescrp: string;
  categoriesName: string;
  accountSap: string;
  ctaPs: string;
  accountDescription: string;
  presupuestoAnualTotal: string;
  estimatedTotal: string;
  diferencia: string;
  porcentajeDiferencia: string;
  justificationCommentary: string;
}

export enum Status {
  Approved = 'APROBADO',
  Refused = 'RECHAZADO'
}
export interface GlobalEstimatedClose extends BasicEntity {
  zoneGlobalDescrp: string;
  categoriesName: string;
  accountSap: string;
  ctaPs: string;
  costCenterSap: string;
  description: string;
  zoneDescrp: string;
  accountDescription: string;
  yearCurrent: string;
  presupuestoAnualTotal: string;
  estimatedTotal: string;
  justificationCommentary: string;
  estimatedDescription: string;
}
export interface RefusedEstimatedClose extends BasicEntity {
  options: any;
  estimatedDescription: string;
  zoneGlobalDescrp: string;
  accountSap: string;
  ctaPs: string;
  accountDescription: string;
  costCenterName: string;
  estimatedTotal: string;
  comment: string;
  justificationCommentary: string;
}
export interface GeneralZoneReport extends BasicEntity {
  categoriesName: string;
  zoneGlobalDescrp: string;
  description: string;
  costCenterName: string;
  costCenterSap: string;
  accountDescription: string;
  accountSap: string;
  gl: string;
  provision: string;
  reclasificacion: string;
  hfm: string;
  total: string;
  budgetYear: string;
  estimatedTotal: string;
  totalVsEstimatedclose: string;
}

export interface Categories extends BasicEntity {
  categoriesName: string;
}

export interface HFM extends BasicEntity {
  categoriesName: string;
  accountSap: String;
  accountDescription: String;
  costCenterSap: string;
  loadMonth: string;
  totalGeneral: string;
}

export interface SubCostCenter extends BasicEntity {
  description: string;
  zoneId: string;
  zoneDescrp: string;
}

export interface DictionaryCc extends BasicEntity {
  accountSap: string;
  accountDescription: string;
  categoriesName: string;
  descripAccount: string;
  categoriesId: string;
  dateValidFrom: string;
  dateValidTo: string;
}

export interface AreaManager extends BasicEntity {
  codeCostCenter: string;
  costCenterName: string;
  description: string;
  zoneDescrp: string;
  zoneGlobalDescrp: string;
  levelOne: string;
  levelTwo: string;
  levelThree: string;
}

export interface User extends BasicEntity {
  userAccountId: string;
  userIsActive: string;
  name: string;
  userEmail: string;
  userPassword: string | null;
  userUsername: string;
  userTypeName: Array<string>;
  zoneGlobalDescrp: Array<string>;
  subCategoriesName: Array<string>;
  subCategoriesLevelOne: Array<string> | null;
  subCategoriesLevelTwo: Array<string> | null;
  subCategoriesLevelThree: Array<string> | null;
  userLevels: string;
  userCreated?: string;
  userUpdated?: string;
  isAdmin?: boolean;
  isNotifiable?: boolean;
}

export interface Map extends BasicEntity {
  cityName: string;
  zones: Array<string>;
}

export interface BasicEntity {
  //TODO: remove userId and costCenterSap from here and from EDIT_ENTITY case from entitiesReducer
  _id: string;
  userId?: string;
  costCenterSap?: string;
}

export interface Files extends BasicEntity {
  filesId: string;
  filesName: string;
  filesImg: string;
}

/* PORTEO */
export interface Carrier extends BasicEntity {
  carrierId: string;
  carrierName: string;
  carrierRut: string;
  bp: string;
  managementTypeId: string;
  managementTypeName: string;
  truckBayQuantity: string;
  omitOnShipmentCarriage: boolean;
  carrierTypeCarriageId: boolean;
  carrierTypeCarriageName: boolean;
}

export interface ManagementType extends BasicEntity {
  managementTypeId: string;
  managementTypeName: string;
}

export interface Tariff extends BasicEntity {
  bp: string;
  carrierName: string;
  zonePortingDescription: string;
  tariff: string;
  contingency: string;
  tariffTotal: string;
  contingencyEett: string;
  totalContingency: string;
  carrierTypeId: string;
  carrierTypeName: string;
  dateValidFrom: string;
  dateValidTo: string;
}

export interface CarrierType extends BasicEntity {
  carrierTypeId: string;
  carrierTypeName: string;
}
export interface Gloss extends BasicEntity {
  subConceptDescription: string;
  subConceptId: string;
  conceptName: string;
  distributionCenter: string;
  zonePortingDescription: string;
  zonePortingId: string;
  distributionCenterZona: string;
  monthPayment: string;
  monthClosing: string;
}
export interface ZonePorting extends BasicEntity {
  zonePortingId: string;
  zonePortingDescription: string;
}

export interface Concept extends BasicEntity {
  conceptName: string;
}
export interface SubConcept extends BasicEntity {
  subConceptId: string;
  subConceptDescription: string;
  conceptId: string;
  conceptName: string;
}

export interface Volumen extends BasicEntity {
  fecha: string;
  entregado: string;
  facturado: string;
}

export interface FreightCharges extends BasicEntity {
  bp: string;
  zonePortingDescription: string;
  documentDate: string;
  carrierRut: string;
  carrierName: string;
  carrierTypeName: string;
  netPrice: string;
  tax: string;
  grossPrice: string;
  purchaseOrder: string;
}

export interface EquivalentBox extends BasicEntity {
  bp: string;
  zonePortingDescription: string;
  carrierName: string;
  equivalentBoxDate: string;
  boxQuantity: string;
}

export interface MajorPorting extends BasicEntity {
  documentNumber: string;
  accountingDate: string;
  amount: string;
  freightGloss: string;
  carrierRut: string;
}

export interface ContingencyWithholding extends BasicEntity {
  date: string;
  freightGloss: string;
  contingencyWithholding: string;
}

export interface ContingencyWithholdingInconsistencies extends BasicEntity {
  accountingDate: string;
  freightGloss: string;
  bp: string;
  carrierName: string;
  majorValue: string;
  equivalentBoxValue: string;
  difference: string;
}

export interface FareDifference extends BasicEntity {
  zonePortingDescription: string;
  carrierName: string;
  tariffTotal: string;
  boxQuantity: string;
  total: string;
}

export interface FareDifferenceSummary extends BasicEntity {
  carrierName: string;
  january: string;
  januaryPercentage: string;
  february: string;
  februaryPercentage: string;
  march: string;
  marchPercentage: string;
  april: string;
  aprilPercentage: string;
  may: string;
  mayPercentage: string;
  june: string;
  junePercentage: string;
  july: string;
  julyPercentage: string;
  august: string;
  augustPercentage: string;
  september: string;
  septemberPercentage: string;
  october: string;
  octoberPercentage: string;
  november: string;
  novemberPercentage: string;
  december: string;
  decemberPercentage: string;
  total: string;
}

export interface EstimatedClosingPorting extends BasicEntity {
  typeInfo: string;
  carrierTypeName: string;
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
  total: string;
}

export interface FreightProvision extends BasicEntity {
  freightProvisionMonth: string;
  freightProvisionAmount: string;
}

export interface TimeRoute extends BasicEntity {
  timeRouteIdCcu: string;
  timeRouteId: string;
  route: string;
  time: PostgresInterval;
}

export interface Ifac extends BasicEntity {
  period: string;
  date: string;
  a023: string;
  a050: string;
  a061: string;
  a071: string;
  a080: string;
  a090: string;
  total: string;
}

export interface IfacSimulated extends BasicEntity {
  type: string;
  period: string;
  date: string;
  a023: string;
  a050: string;
  a061: string;
  a071: string;
  a080: string;
  a090: string;
  total: string;
}

export interface FreightTimeMaster extends BasicEntity {
  timeDate: string;
  dayOfWeek: string;
  weekDate: string;
  monthDate: string;
  isHoliday: boolean;
  isBusinessDay: boolean;
}

export interface ForeignExchange extends BasicEntity {
  valueDateMonth: string;
  valueDateDay: string;
  valueDate: string;
  usd: string;
  uf: string;
  dsl: string;
  fileName: string;
}

export interface ForeignExchangeEstimated extends BasicEntity {
  month: string;
  usd: string;
  uf: string;
  dsl: string;
}

export interface ForeignExchangeResume extends BasicEntity {
  foreignExchangeType: string;
  currencyName: string;
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
}

export interface PermanentTruck extends BasicEntity {
  carrierName: string;
  zonePortingDescription: string;
  freightGloss: string;
  tariffDate: string;
  truckQuantity: string;
  netTariff: string;
  contingentWithholding: string;
  totalTariff: string;
}

export interface CarrierZoneGloss extends BasicEntity {
  carrierId: string;
  bp: string;
  carrierRut: string;
  carrierName: string;
  freightGlossId: string;
  freightGlossSap: string;
  freightGloss: string;
  zonePortingId: string;
  zonePortingDescription: string;
}

export interface FreightGloss extends BasicEntity {
  freightGlossSap: string;
  freightGloss: string;
}

export interface ContractorTariff extends BasicEntity {
  zonePortingDescription: string;
  carrierName: string;
  tariffDate: string;
  tariff: string;
  boxQuantity: string;
  total: string;
}

export interface FreightExtra extends BasicEntity {
  conceptName: string;
  subConceptDescription: string;
  carrierName: string;
  carrierRut: string;
  bp: string;
  purchaseRequest: string;
  purchaseOrder: string;
  noteOrder: string;
  distributionCenterZona: string;
  distributionCenter: string;
  zonePortingDescription: string;
  grossAmount: string;
  netAmount: string;
  monthPayment: string;
  monthClosing: string;
}

export interface FreightExtraGlSap extends BasicEntity {
  carrierName: string;
  carrierRut: string;
  bp: string;
  freightExtraGlSapMonth: string;
  freightExtraGlSapDate: string;
  amount: string;
  costCenterSap: string;
  accountSap: string;
}

export interface FreightExtraInconsistencies extends BasicEntity {
  carrierName: string;
  carrierRut: string;
  bp: string;
  accountingDate: string;
  extraAmount: string;
  glSapAmount: string;
}

export interface FreightExtraResume extends BasicEntity {
  conceptName: string;
  subConceptDescription: string;
  carrierName: string;
  carrierRut: string;
  bp: string;
  purchaseRequest: string;
  purchaseOrder: string;
  noteOrder: string;
  distributionCenterZona: string;
  distributionCenter: string;
  zonePortingDescription: string;
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
  total: string;
}

export interface CarrierTypeCarriage extends BasicEntity {
  carrierTypeCarriageName: string;
}

export interface CarrierRouteCarriage extends BasicEntity {
  carrierRouteCarriageId: string;
  dateValidFrom: string;
  dateValidTo: string;
  carrierId: string;
  carrierName: string;
  timeRouteId: string;
  route: string;
  time: PostgresInterval;
  timeRouteIdCcu: string;
  carrierTypeCarriageId: string;
  carrierTypeCarriageName: string;
  tariff: string;
}
export interface TariffCarriage extends BasicEntity {
  tariffCarriageId: string;
  carrierRouteCarriageId: string;
  carrierId: string;
  carrierName: string;
  timeRouteId: string;
  route: string;
  time: PostgresInterval;
  timeRouteIdCcu: string;
  carrierTypeCarriageId: string;
  carrierTypeCarriageName: string;
  tariff: string;
  tariffDate: string;
  isFirstTariff: boolean;
  tariffExtra: string;
  tariffPolynomial: string;
  totalTariff: string;
}

export interface ExtraCarriage extends BasicEntity {
  extraCarriageDate: string;
  category: string;
  carrierRut: string;
  carrierName: string;
  netAmount: string;
  grossAmount: string;
  gloss: string;
  purchaseRequest: string;
  purchaseOrder: string;
  noteOrder: string;
  comment: string;
  invoice: string;
}

export interface ReportUEN extends BasicEntity {
  extraCarriageDate: string;
  carrierRut: string;
  carrierName: string;
  netAmount: string;
  grossAmount: string;
  gloss: string;
}

export interface ShipmentCarriage extends BasicEntity {
  shipmentNumber: string;
  shipmentClassCarriageIdCcu: string;
  timeRouteIdCcu: string;
  oca: string;
  shipmentPlanStation: string;
  bp: string;
  createdBy: string;
  createdDateSource: string;
  vehiclePatent: string;
  trailerPatent: string;
  endDateCarriageReport: string;
  route: string;
  carrierName: string;
  chargePackingType: string;
  modifiedBy: string;
  totalTariff: string;
  truckBayQuantity: string;
  pxq: string;
}

export interface ShipmentCarriagePXQ extends ShipmentCarriage {
  carrierTypeCarriageName: string;
}

export interface ResumeShipmentCarriage extends BasicEntity {
  carrierTypeCarriageName: string;
  carrierName: string;
  pxq: string;
  travelQuantity: string;
  pxqPercentageByGroup: string;
  pxqPercentageTotal: string;
}

export interface ShipmentAuditingCarriage extends BasicEntity {
  shipmentAuditingCarriageId: string;
  timeDifferenceOca: PostgresInterval;
  authorizedForPay: string;
  authorizedBy: string;
  action: string;
  shipmentNumber: string;
  shipmentClassCarriageIdCcu: string;
  oca: string;
  createdBy: string;
  createdDateSource: string;
  vehiclePatent: string;
  trailerPatent: string;
  endDateCarriageReport: string;
  timeRouteIdCcu: string;
  route: string;
  time: PostgresInterval;
  carrierName: string;
  chargePackingType: string;
  modifiedBy: string;
  isAudited: string;
}

export interface Polynomial extends BasicEntity {
  date: string;
  usdValue: string;
  ufValue: string;
  dslValue: string;
}

export interface ProvisionCarriage extends BasicEntity {
  carrierName: string;
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
  total: string;
}

export interface BudgetCarriage extends BasicEntity {
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
  total: string;
}

export interface CarriageResume extends BasicEntity {
  group: string;
  name: string;
  type: string;
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
  total: string;
}

export interface Notification extends BasicEntity {
  notificationTypeName: string;
  userUsername: Array<string>;
}

export interface NotificationType extends BasicEntity {
  notificationTypeId: string;
  notificationTypeName: string;
  notificationTypeDescription: string;
}
