import { Box, Stack } from '@mui/material';
import { GlobalEstimatedClose } from '../types';
import { formatStringNumber } from '../utils/utils';
import { WebEntity } from './types';

const EstimatedGlobalClosingEntity: WebEntity<GlobalEstimatedClose> = {
  name: 'globalEstimatedClose',
  endpoint: 'expenses/EstimatedClose/globalFilterEstimatedClose',
  referenceColumn: 'globalEstimatedClose',
  tableColumns: [
    {
      header: 'Zona Global',
      accessorKey: 'zoneGlobalDescrp',
      id: 'zoneGlobalDescrp',
      columnType: 'text',
      size: 180
    },
    {
      header: 'Categoria',
      accessorKey: 'categoriesName',
      id: 'categoriesName',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Cta',
      accessorKey: 'accountSap',
      id: 'accountSap',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Cta PS',
      accessorKey: 'ctaPs',
      id: 'ctaPs',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Centro Costo Sap',
      accessorKey: 'costCenterSap',
      id: 'costCenterSap',
      columnType: 'text',
      size: 230
    },
    {
      header: 'Zona',
      accessorKey: 'zoneDescrp',
      id: 'zoneDescrp',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Nivel 4',
      accessorKey: 'description',
      id: 'description',
      columnType: 'text',
      size: 150
    },
    {
      header: 'Descripcion Categoria',
      accessorKey: 'accountDescription',
      id: 'accountDescription',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Año',
      accessorKey: 'yearCurrent',
      id: 'yearCurrent',
      columnType: 'text',
      size: 130
    },
    {
      header: 'Presupuesto Anual',
      accessorKey: 'presupuestoAnualTotal',
      id: 'presupuestoAnualTotal',
      columnType: 'numeric',
      size: 320,
      Cell: ({ row }) => formatStringNumber(row?.original?.presupuestoAnualTotal),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum?.toString())}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + parseInt(obj.presupuestoAnualTotal), 0);

        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString())}
          </Stack>
        );
      }
    },
    {
      header: 'Estimado Cierre',
      accessorKey: 'estimatedTotal',
      id: 'estimatedTotal',
      columnType: 'numeric',
      size: 320,
      Cell: ({ row }) => formatStringNumber(row?.original?.estimatedTotal),
      aggregationFn: 'sum',
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <Box sx={{ color: 'success.main', fontWeight: 'bold' }}>
            {sum && formatStringNumber(sum?.toString())}
          </Box>
        );
      },
      Footer: ({ table }) => {
        const rows = table.getFilteredRowModel().rows.map((r) => r.original);
        const totalFooter = rows.reduce((acc, obj) => acc + parseInt(obj.estimatedTotal), 0);

        return (
          <Stack sx={{ fontSize: 15, color: 'green' }}>
            {formatStringNumber(totalFooter.toString())}
          </Stack>
        );
      }
    },
    {
      header: 'Comentario',
      accessorKey: 'justificationCommentary',
      id: 'justificationCommentary',
      columnType: 'text',
      size: 250
    },
    {
      header: 'Categoria Comentario',
      accessorKey: 'estimatedDescription',
      id: 'estimatedDescription',
      columnType: 'text',
      size: 250
    }
  ]
};

export const PENDINGS_ESTIMATED_CLOSED_COLUMNS = {
  categorieName: 'Categoria',
  accountSap: 'Cta',
  ctaPs: 'Cta PS',
  accountDescription: 'Descipcion Cuenta',
  estimatedTotal: 'Estimado Cierre'
};

export default EstimatedGlobalClosingEntity;
